<template>
  <section class="section section__filter">
    <h2>Проекты</h2>
    <div class="filter__form-change-wrap">
      <form
        @submit.prevent="submitFilterForm"
        ref="filterForm"
        class="filter__form filter__form-change"
        autocomplete="off"
      >
        <div class="search filter search__long">
          <div class="search__input">
            <label for="docs_search" class="search__label"> Название проекта для поиска </label>
            <div class="search__input__wrap">
              <input v-model="form.title.value" id="docs_search" type="text" maxlength="255" />
              <button v-if="form.title.value" type="button" @click="resetSearchInput">
                <CloseIcon />
              </button>
            </div>
          </div>
          <div class="filter__item">
            <label>Сфера деятельности</label>
            <Multiselect
              track-by="id"
              label="title"
              v-model="form.category_id.value"
              :options="sortedMainCategories"
              placeholder="Все сферы"
              selectLabel="Выбрать ↵"
              deselectLabel="Удалить ↵"
              selectedLabel="Выбрано"
              :searchable="true"
              :allow-empty="true"
            >
              <span slot="noOptions">Список пуст</span>
            </Multiselect>
          </div>
          <div class="search__btn search__btn--with-clear">
            <a @click.prevent="resetFilterForm" href="#" class="filter__reset"> Сбросить фильтр </a>
            <button type="submit" class="btn-blue_dark" :disabled="loading">
              <LoadingIndicator v-if="loading" title="Загрузка" />
              <template v-else>Найти</template>
            </button>
          </div>
        </div>
      </form>

      <a
        @click.prevent="changeFilterForm"
        class="filter__change-btn"
        :class="{ hide__btn: isFilterVisible }"
        href="#"
      >
        <span v-if="isFilterVisible">Скрыть фильтр</span>
        <span v-else>Поиск и фильтр по медиа</span>
        <ArrowDownIcon />
      </a>
    </div>
  </section>
</template>

<script>
import Multiselect from "vue-multiselect";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import CloseIcon from "@/components/svg/CloseIcon.vue";
import ArrowDownIcon from "@/components/svg/ArrowDown.vue";

export default {
  name: "ProjectsFilter",
  components: {
    Multiselect,
    LoadingIndicator,
    CloseIcon,
    ArrowDownIcon,
  },
  data() {
    return {
      loading: false,
      isFilterVisible: false,
      form: {
        title: {
          value: null,
          message: null,
        },
        category_id: {
          value: null,
          message: null,
        },
        type_id: {
          value: this.$store.state.type.CODE_PROJECTS_NEWS,
          message: null,
        },
      },
    };
  },
  computed: {
    mainCategories() {
      if (this.$store.state.main_categories && this.$store.state.main_categories.length) {
        return this.$store.state.main_categories.filter((item) => {
          return item.status.code === this.$store.state.status.CODE_ACTIVE;
        });
      }
      return [];
    },
    sortedMainCategories() {
      let newCategories = [];
      this.mainCategories.forEach((item) => {
        newCategories.push(item);
        if (item.child_categories && item.child_categories.length) {
          newCategories = newCategories.concat(
            item.child_categories.filter(
              (child) => child.status.code === this.$store.state.status.CODE_ACTIVE
            )
          );
        }
      });
      return newCategories;
    },
  },
  methods: {
    changeFilterForm() {
      let filter = this.$refs.filterForm;
      filter.style.display = this.isFilterVisible ? "none" : "flex";
      this.isFilterVisible = !this.isFilterVisible;
    },
    /**
     * Фильтр проектов
     * @returns {Promise<void>}
     */
    async submitFilterForm() {
      if (!this.loading) {
        this.loading = true;
        const fd = new FormData();
        /**
         * Сбор всех полей формы и закрепление в formData
         */
        Object.keys(this.form).forEach((key) => {
          if (key === "category_id") {
            if (this.form[key].value) fd.append(key, this.form[key].value.id);
          } else if (key === "type_id") {
            fd.append(key, this.$store.state.type.CODE_PROJECTS_NEWS);
          } else {
            if (this.form[key].value !== null) fd.append(key, this.form[key].value);
          }
        });
        this.$store.state.filterData = fd;
        await this.$store
          .dispatch("POST_NEWS_FILTER", { data: fd })
          .then((response) => {
            this.loading = false;
            this.$store.state.filterResponse = response.data.news;
          })
          .catch((response) => {
            this.loading = false;
            console.error(response.data);
          });
      }
    },
    /**
     * Сброс фильтра
     */
    resetFilterForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key].value = null;
      });
      this.$store.state.filterData = null;
      this.$store.state.filterResponse = null;
      this.$store.state.filterList = [];
    },
    /**
     * Очищение строки поиска
     */
    resetSearchInput() {
      this.form.title.value = null;
    },
  },
};
</script>

<style lang="stylus">
@import '~flatpickr/dist/flatpickr.min.css'
@import '~vue-multiselect/dist/vue-multiselect.min.css'
</style>
