<template>
  <main class="main orgs">
    <div class="container">
      <div class="main__inner">
        <ProjectsFilter />
        <section class="section section__projects">
          <div>
            <div v-if="projects && projects.data && projects.data.length" class="projects-list _items_list">
              <router-link
                v-for="(item, index) in projects.data"
                :key="index"
                :to="{
                  name: 'news_item',
                  params: { id: item.id, url: item.link },
                }"
                class="projects-item"
                :class="{ 'projects-item__noimg': !item.head_img }"
              >
                <div class="project-img">
                  <div class="img__container">
                    <div class="img__upper__layer"></div>
                    <img
                      v-if="item.head_img && item.head_img.img && item.head_img.img.url"
                      :src="item.head_img | image($store.state.api)"
                      :alt="item.head_img | image_alt"
                    />
                  </div>
                </div>
                <div class="project__info">
                  <div class="project__title" v-if="item.title">
                    {{ item.title }}
                  </div>
                  <div class="project__category" v-if="item.category && item.category.title">
                    {{ item.category.title }}
                  </div>
                </div>
              </router-link>
            </div>

            <p v-else-if="!$store.state.filterResponse" class="_item__text">Проектов нет</p>
            <p v-else-if="$store.state.filterData.get('title')" class="_item__text">
              По запросу "{{ $store.state.filterData.get("title") }}" ничего не найдено
            </p>
            <p v-else class="_item__text">По вашему запросу ничего не найдено</p>
          </div>

          <a v-if="loading" href="#" class="show-more-link">
            <LoadingIndicator title="Загрузка" />
          </a>
          <a
            @click.prevent="submitFilterForm"
            v-else-if="$store.state.filterResponse && $store.state.filterResponse.next_page_url"
            href="#"
            class="show-more-link"
          >
            Показать ещё
          </a>
          <a @click.prevent="loadMore" v-else-if="projects.next_page_url" href="#" class="show-more-link">
            Показать ещё
          </a>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import axios from "axios";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import ProjectsFilter from "@/views/local/projects/components/ProjectsFilter.vue";

export default {
  name: "LocalProjects",
  async asyncData({ store }) {
    await store.dispatch("GET_PROJECTS");
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    projects() {
      if (this.$store.state.filterResponse) {
        return this.$store.state.filterResponse;
      }
      return this.$store.state.projects;
    },
  },
  methods: {
    /**
     * Загрузить еще проекты
     * @returns {Promise<void>}
     */
    async loadMore() {
      if (!this.loading) {
        this.loading = true;
        await axios
          .get(this.projects.next_page_url)
          .then(({ data }) => {
            this.loading = false;
            // добавляем к существующим новые документы с новой страницы пагинации
            this.$store.state.projects.data = this.$store.state.projects.data.concat(data.projects.data);
            this.$store.state.projects.next_page_url = data.projects.next_page_url;
          })
          .catch(({ response }) => {
            this.loading = false;
            console.error(response?.data?.message);
          });
      }
    },
    /**
     * Запрос для получения следующей страницы пагинации
     */
    async submitFilterForm() {
      if (!this.loading) {
        this.loading = true;
        await this.$store
          .dispatch("POST_NEWS_FILTER", {
            data: this.$store.state.filterData,
            url: this.$store.state.filterResponse.next_page_url,
          })
          .then((response) => {
            this.loading = false;
            const oldResponse = this.$store.state.filterResponse;
            this.$store.state.filterResponse = response.data.news;
            this.$store.state.filterResponse.data = oldResponse.data.concat(
              this.$store.state.filterResponse.data
            );
          })
          .catch((response) => {
            this.loading = false;
            console.error(response.data);
          });
      }
    },
    /**
     * Сброс фильтра
     */
    resetFilterForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key].value = null;
      });
    },
    /**
     * Очищение строки поиска
     */
    resetSearchInput() {
      this.form.title.value = null;
    },
  },
  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
  components: {
    LoadingIndicator,
    ProjectsFilter,
  },
};
</script>

<style lang="stylus">
@import '~vue-multiselect/dist/vue-multiselect.min.css'
@import "~@/styles/mixins/ratio.styl"
@import "~@/styles/mixins/textcut.styl"

.section.section__projects {
  padding-top: 0;
}

.projects {
  &-list {
    display grid
    grid-template-columns 1fr 1fr
    +below(640px) {
      grid-template-columns 1fr
    }
  }

  &-item {
    position relative

    &__noimg {
      display flex
      border 1px solid var(--color_gray_divider)
      overflow hidden
      transition 0.2s

      &:hover {
        background var(--color_gray_divider)
      }

      .project__info {
        height 100%
        display flex
        flex-direction column
        justify-content space-between
      }

      .project__title {
        color var(--color_dark)
      }

      .project__category {
        color var(--color_yellow_dark)
      }
    }

    &:hover {
      .project-img:after {
        opacity: 1
      }

      .project-img img {
        transform scale(1.08)
      }

      .project__title {
        color var(--color_blue)
      }
    }
  }
}

.project {
  &-img {
    ratio(752, 506)
    overflow hidden

    &:after {
      content: ""
      display: block
      position: absolute
      width: 100%
      height: 100%
      top: 0
      bottom: 0
      left: 0
      right: 0
      background: rgba(0, 0, 0, 0.15)
      z-index: 1
      opacity: 0
      transition: opacity .3s ease-in
      pointer-events none
    }

    img {
      transition: transform .3s ease-in;
    }
  }

  &__info {
    position absolute
    bottom 0
    left 0
    right: 0;
    z-index 10
    padding 48px
    +below(1200px) {
      padding 24px
    }
    +below(860px) {
      padding 16px
    }
    +below(640px) {
      padding 24px
    }
    +below(420px) {
      padding 16px
    }
  }

  &__title {
    font-size: 1.875em
    line-height: 36px;
    color var(--color_white)
    margin-bottom: 16px;
    transition 0.2s
    textcut(5)
    +below(1200px) {
      font-size 1.375em
      line-height 30px
      margin-bottom: 8px;
    }
    +below(990px) {
      font-size 1.125em
      line-height 24px
    }
    +below(768px) {
      font-size 1em
      line-height 20px
      margin-bottom: 4px;
    }
    +below(640px) {
      font-size 1.375em
      line-height 30px
      margin-bottom: 8px;
    }
    +below(540px) {
      font-size 1.125em
      line-height 24px
    }
    +below(420px) {
      font-size 1em
      line-height 20px
      margin-bottom: 4px;
    }
  }

  &__category {
    font-weight: 500;
    font-size: 1.125em
    line-height: 24px;
    color var(--color_yellow)
    +below(1200px) {
      font-size: 0.875em
      line-height: 20px;
    }
    +below(768px) {
      font-size: 0.75em
      line-height: 16px;
    }
    +below(640px) {
      font-size: 0.875em
      line-height: 20px;
    }
    +below(420px) {
      font-size: 0.75em
      line-height: 16px;
    }
  }
}
</style>
